import React, { useState } from "react";

import styled from "styled-components";
import MainContainer from "./Components/MainContainer";
import SidebarContainer from "./Components/SideBottom/SidebarContainer";
import { useMediaQuery } from "react-responsive";
import { adelheid } from "./Photos/adelheid";

function App() {
  const [Album, setAlbum] = useState(adelheid);
  const [photoMeta, setPhotoMeta] = useState(adelheid[0]);
  const [photoIndex, setphotoIndex] = useState(0);

  const selectAlbum = (album: any) => {
    setphotoIndex(0);
    setAlbum(album);
  };

  const leftClick = () => {
    if (photoIndex == 0) {
      setphotoIndex(Album.length - 1);
    } else {
      setphotoIndex((photoIndex - 1) % Album.length);
    }
  };

  const rightClick = () => {
    setphotoIndex((photoIndex + 1) % Album.length);
  };

  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 800px)",
  });
  return (
    <C>
      {isMobileDevice && <h1> noMobile ;) go use your pc</h1>}
      {!isMobileDevice && (
        <div>
          <SidebarContainer
            selectAlbum={selectAlbum}
            leftClick={leftClick}
            rightClick={rightClick}
            photoMeta={photoMeta}
          ></SidebarContainer>
          <MainContainer
            photoIndex={photoIndex}
            leftClick={leftClick}
            rightClick={rightClick}
            Album={Album}
            setPhotoMeta={setPhotoMeta}
          ></MainContainer>
        </div>
      )}
    </C>
  );
}

const C = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export default App;
