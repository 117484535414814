import React from "react";
import styled from "styled-components";
import PhotoInfo from "./PhotoInfo";
import PrevNext from "./PrevNext";

export interface SideBottomContainerProps {
  leftClick: () => void;
  rightClick: () => void;
  meta: {
    id: number;
    titel: string;
    descripton: string;
    place: string;
    date: string;
    path: string;
  };
}

export const SideBottomContainer: React.FC<SideBottomContainerProps> = ({
  leftClick,
  rightClick,
  meta,
}) => {
  return (
    <C>
      <PhotoInfo meta={meta}></PhotoInfo>
      <PrevNext leftClick={leftClick} rightClick={rightClick}></PrevNext>
    </C>
  );
};
const C = styled.div`
  position: absolute;
  height: auto;
  width: 100%;
  bottom: 4vh;
`;
export default SideBottomContainer;
