export var adelheid = [
  {
    id: 1,
    titel: "gorgeous grandmother",
    descripton: "",
    place: "London",
    date: "2016",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612273360/Adelheid/9E1E7E3D-FBAF-4C13-8300-EA70B3D5B3DB_1_105_c_snmlwn.jpg",
  },
  {
    id: 2,
    titel: "mhh",
    descripton: "",
    place: "London",
    date: "2016",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612273358/Adelheid/EBE6B7DD-282A-4558-9F20-8DB24BF162E4_1_105_c_jjyjfb.jpg",
  },
  {
    id: 3,
    titel: ".",
    descripton: "",
    place: "London",
    date: "2016",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612273360/Adelheid/C73D33AC-7055-48EC-A8CC-6CA7795584A4_1_105_c_wgtl7q.jpg",
  },
  {
    id: 4,
    titel: "kisses for you",
    descripton: "",
    place: "London",
    date: "2016",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612273359/Adelheid/A13F9FDF-58A1-46D4-85F3-F4282E7A1365_1_105_c_ppzoly.jpg",
  },
  {
    id: 5,
    titel: "strong",
    descripton: "",
    place: "London",
    date: "2016",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612273358/Adelheid/88932375-4BE8-45CB-AB00-47F9088DD5BE_1_105_c_s9x7eh.jpg",
  },
  {
    id: 6,
    titel: "waahh",
    descripton: "",
    place: "London",
    date: "2016",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612273358/Adelheid/B7AFA2AF-6D6A-43EA-82CF-B6CCBAD23C77_1_105_c_mnm23x.jpg",
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  adelheid,
};
