import React from "react";
import styled from "styled-components";

export interface LogoProps {}

export const Logo: React.FC<LogoProps> = () => {
  return (
    <C>
      <P>COSTIN</P>
    </C>
  );
};
const C = styled.div`
  display: flex;
  flex-direction: row;

  position: absolute;
  align-items: flex-start;
  top: 0px;
  width: 100%;
  top: 7.5vh;
`;

const P = styled.p`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;
export default Logo;
