import React from "react";
import styled from "styled-components";

export interface PrevNextProps {
  leftClick: () => void;
  rightClick: () => void;
}

export const PrevNext: React.FC<PrevNextProps> = ({
  leftClick,
  rightClick,
}) => {
  return (
    <C>
      <P onClick={leftClick}>prev</P>
      <P>/</P>
      <P onClick={rightClick}>next</P>
    </C>
  );
};
const C = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;

  align-items: flex-start;
  margin-top: 10px;
`;
const P = styled.p`
  font-size: 12px;
  margin: 0;
`;
export default PrevNext;
