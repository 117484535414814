import React, { useState } from "react";
import styled from "styled-components";
import Display from "./Display";

export interface MainContainerProps {
  photoIndex: number;
  leftClick: () => void;
  rightClick: () => void;
  setPhotoMeta: any;
  Album: {
    id: number;
    titel: string;
    descripton: string;
    place: string;
    date: string;
    path: string;
  }[];
}

export const MainContainer: React.FC<MainContainerProps> = ({
  leftClick,
  rightClick,
  photoIndex,
  setPhotoMeta,
  Album,
}) => {
  setPhotoMeta(Album[photoIndex]);
  return (
    <C>
      <DisplayContainer>
        <Display
          leftClick={leftClick}
          rightClick={rightClick}
          url={Album[photoIndex].path}
        ></Display>
      </DisplayContainer>
    </C>
  );
};
const C = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
`;

const DisplayContainer = styled.div`
  position: relative;
  margin-left: 150px;
`;

export default MainContainer;
