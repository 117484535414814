import React from "react";
import styled from "styled-components";

export interface PhotoInfoProps {
  meta: {
    id: number;
    titel: string;
    descripton: string;
    place: string;
    date: string;
    path: string;
  };
}

export const PhotoInfo: React.FC<PhotoInfoProps> = ({ meta }) => {
  return (
    <C>
      <P>{`${meta.titel} ${meta.date}`}</P>
    </C>
  );
};
const C = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100px;
`;

const P = styled.p`
  font-size: 12px;
  font-weight: bold;
  margin: 0;
`;

export default PhotoInfo;
