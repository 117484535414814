import React from "react";
import styled from "styled-components";
import Logo from "../Logo";
import SideBottomContainer from "./SideBottomContainer";
import { adelheid } from "../../Photos/adelheid";
import { same } from "../../Photos/same";
import { lech } from "../../Photos/lech";
export interface SidebarContainerProps {
  selectAlbum: (album: any) => void;
  leftClick: () => void;
  rightClick: () => void;
  photoMeta: {
    id: number;
    titel: string;
    descripton: string;
    place: string;
    date: string;
    path: string;
  };
}

export const SidebarContainer: React.FC<SidebarContainerProps> = ({
  selectAlbum,
  leftClick,
  rightClick,
  photoMeta,
}) => {
  return (
    <C>
      <Logo></Logo>
      <P onClick={() => selectAlbum(adelheid)}>Adelheid</P>
      <P onClick={() => selectAlbum(lech)}>Lech</P>

      <SideBottomContainer
        meta={photoMeta}
        leftClick={leftClick}
        rightClick={rightClick}
      ></SideBottomContainer>
    </C>
  );
};
const C = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: white;
  left: 0;
  top: 0;
  height: 100%;
  width: 100px;
  padding-left: 3rem;

  background-color: white;
  z-index: 5;
`;

const P = styled.p`
  font-size: 14px;
  opacity: 0.8;
  margin: 0;
  margin-top: 5px;
  cursor: pointer;

  :active {
    font-weight: bold;
  }
`;

export default SidebarContainer;
