export var lech = [
  {
    id: 1,
    titel: "untitled",
    descripton: "",
    place: "lech",
    date: "2019",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612188068/Lech/lech1-bw-16_n2vvoq.jpg",
  },
  {
    id: 2,
    titel: "untitled",
    descripton: "",
    place: "lech",
    date: "2019",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612188067/Lech/lech1-bw-17_yzz9wf.jpg",
  },
  {
    id: 3,
    titel: "untitled",
    descripton: "",
    place: "lech",
    date: "2019",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612188075/Lech/lech1-bw-26_iocwjo.jpg",
  },
  {
    id: 5,
    titel: "untitled",
    descripton: "",
    place: "lech",
    date: "2019",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612188079/Lech/lech1-bw-33_tl7hvz.jpg",
  },
  {
    id: 4,
    titel: "untitled",
    descripton: "",
    place: "lech",
    date: "2019",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612188075/Lech/lech1-bw-31_tsovhn.jpg",
  },
  {
    id: 6,
    titel: "untitled",
    descripton: "",
    place: "lech",
    date: "2019",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612188076/Lech/lech1-bw-28_ffnace.jpg",
  },

  {
    id: 8,
    titel: "untitled",
    descripton: "",
    place: "lech",
    date: "2019",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612188072/Lech/lech1-bw-29_vmhpvp.jpg",
  },
  {
    id: 9,
    titel: "untitled",
    descripton: "",
    place: "lech",
    date: "2019",
    path:
      "https://res.cloudinary.com/djsitinuj/image/upload/v1612188075/Lech/lech1-bw-32_uymjd9.jpg",
  },
];

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  lech,
};
