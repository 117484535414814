import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

export interface DisplayProps {
  leftClick: () => void;
  rightClick: () => void;
  url: String;
}

export const Display: React.FC<DisplayProps> = ({
  leftClick,
  rightClick,
  url,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  return (
    <C>
      <LeftClick onClick={leftClick}></LeftClick>
      <RightClick onClick={rightClick}></RightClick>
      <ContainerIMG>
        <CurrentIMG
          onLoad={() => setImageLoaded(true)}
          key={`${url}`}
          src={`${url}`}
        />
      </ContainerIMG>
    </C>
  );
};
const C = styled.div`
  display: flex;
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const ContainerIMG = styled.div``;

const CurrentIMG = styled.img`
  position: relative;
  height: auto;
  max-height: 85vh;
  max-width: 80vw;
  width: auto;
  z-index: 1;
  animation: ${fadeIn} 0.5s linear;
`;

const LeftClick = styled.div`
  cursor: w-resize;
  position: absolute;
  top: 0px;
  left: -100px;
  right: 50%;
  bottom: 0px;
  z-index: 10;
`;

const RightClick = styled.div`
  cursor: e-resize;
  position: absolute;
  top: 0px;
  left: 50%;
  right: -100px;
  bottom: 0px;
  z-index: 10;
`;

export default Display;
